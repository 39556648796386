<template>
	<div>
		<template v-for="(item, index) in list">
			<div class="row item a" @click="navDetail(item.id)">
				<div class="left">
					<div class="row cb title-box">
						<div class="title text-ellipsis">{{ item.title }}</div>
						<div class="row cc finish" v-if="item.reply_status == 3">已解决</div>
					</div>

					<div class="content text-ellipsis-2">{{ item.content }}</div>
					<div class="row operate">
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/1.png')" class="img"></el-image>
							<span>{{ item.view_num }} 次浏览</span>
						</div>
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/2.png')" class="img"></el-image>
							<span>{{ item.focus_num }} 条关注</span>
						</div>
						<div class="row cc btn">
							<el-image :src="require('@/pc/assets/ask/3.png')" class="img"></el-image>
							<span>{{ item.answer_num }} 个回答</span>
						</div>
						<div class="date">回答于 {{ item.create_time_text }}</div>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {};
	},
	methods: {
		navDetail(id) {
			this.$router.push({
				path: '/ask/detail',
				query: { id }
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './que.less';
</style>
