<template>
	<div class="home-page">
		<div class="row tab-view">
			<template v-for="(item, index) in tabs">
				<div class="row cc tab-item a" :class="{ active: tabIndex == index }" @click="tabChange(index)">
					{{ item }}
					<template v-if="pageShow">
						<span v-if="index == 0">({{ dataCount.answer_num }})</span>
						<span v-else-if="index == 1">({{ dataCount.send_question_num }})</span>
						<span v-else-if="index == 2">({{ dataCount.fans_num }})</span>
						<span v-else="index == 3">({{ dataCount.focus_user_num }})</span>
					</template>
				</div>
			</template>
		</div>
		<div style="height: 62px;"></div>
		<div class="main">
			<template v-if="tabIndex == 0">
				<div class="row statistics" v-if="pageShow">
					截至{{ answerStatistics.end_time }}，{{ answerStatistics.nickname }} 的回答共被采纳 {{ answerStatistics.accept_num }}/{{
						answerStatistics.answer_num
					}}次，采纳率为 {{ answerStatistics.acceptScale }}%
				</div>
				<answer :list="list"></answer>
			</template>
			<template v-else-if="tabIndex == 1">
				<div class="row statistics" v-if="pageShow">
					截至{{ askStatistics.end_time }}，{{ askStatistics.nickname }} 的提问已采纳 {{ askStatistics.accept_num }}/{{ askStatistics.que_count }}次，采纳率为
					{{ askStatistics.acceptScale }}%
				</div>
				<que :list="list"></que>
			</template>
			<template v-else>
				<fans :list="list"></fans>
			</template>
			<div class="paginate-view">
				<div class="row cc loading-view" v-show="loading && !list.length == 0">加载中……</div>
				<div class="skeleton-view" v-show="loading && list.length == 0">
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
					<el-skeleton animated class="skeleton-item" />
				</div>
				<div v-show="!loading && list.length == 0"><el-empty></el-empty></div>
				<div class="row cc pagination" v-show="total > 0">
					<el-pagination background layout="prev,pager,next" :current-page="page" :page-size="pageSize" :total="total" @current-change="pageChange"></el-pagination>
				</div>
			</div>
		</div>
		<div style="height: 30px;"></div>
	</div>
</template>

<script>
import fans from './components/fans.vue';
import que from './components/que.vue';
import answer from './components/answer.vue';
export default {
	data() {
		return {
			pageShow: false,
			tabs: ['回答', '提问', '粉丝', '关注'],
			tabIndex: 0,
			answerStatistics: {},
			askStatistics: {},
			dataCount: {},
			page: 1,
			pageSize: 15,
			list: [],
			loading: true,
			total: 0
		};
	},
	components: { fans, que, answer },
	watch: {
		tabIndex() {
			this.reload();
		}
	},
	computed: {
		url() {
			if (this.tabIndex == 0) {
				return 'user_info/replyList';
			} else if (this.tabIndex == 1) {
				return 'user_info/sendQuesList';
			} else if (this.tabIndex == 2) {
				return 'user_info/myFansList';
			} else {
				return 'user_info/focusUserList';
			}
		}
	},

	async created() {
		var { data } = await this.$service.post('user_info/statistics', { type: 1 });
		this.answerStatistics = data;
		var { data } = await this.$service.post('user_info/statistics', { type: 2 });
		this.askStatistics = data;
		var { data } = await this.$service.post('user_info/dataCount', { user_id: this.$route.query.user_id || '' });
		this.dataCount = data;
		await this.loadData();
		this.pageShow = true;
	},
	methods: {
		pageChange(e) {
			this.list = [];
			this.page = e;
			this.loadData();
		},
		tabChange(index) {
			if (this.loading) return;
			this.tabIndex = index;
		},
		reload() {
			this.page = 1;
			this.list = [];
			this.loadData();
		},
		async loadData() {
			window.scroll(0, 0);
			this.loading = true;
			let res = await this.$service.post(this.url, {
				page: this.page,
				pageSize: this.pageSize,
				user_id: this.$route.query.user_id || ''
			});
			this.total = res.data.total;
			this.list = res.data.data;
			this.loading = false;
		}
	}
};
</script>

<style scoped lang="less">
@import './home.less';
</style>
