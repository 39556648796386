<template>
	<div class="list">
		<div v-for="(item, index) in list">
			<div class="fans-row row" @click="navDetail(item.user_id)">
				<el-image fit="contain" :src="item.avatar" class="avatar"></el-image>
				<div class="col user-info">
					<div class="username">{{ item.nickname }}</div>
					<div class="row auth" v-if="item.is_cert == 1">
						<el-image fit="contain" :src="require('@/pc/assets/user/auth.png')" class="img"></el-image>
						<div class="status">{{ item.major_name }}</div>
					</div>
				</div>
				<div class="row count-group">
					<div class="count-item">获赞：{{ item.agree_num }}</div>
					<div class="count-item">粉丝：{{ item.fans_num }}</div>
					<div class="count-item">关注：{{ item.focus_num }}</div>
				</div>
				<div class="row cc btn a" :class="{ active: item.is_focus === '0' }" @click.stop="sub(index)">
					<template v-if="item.is_focus === '1'">
						已关注
					</template>
					<template v-else-if="item.is_focus == 2">
						互相关注
					</template>
					<template v-else>
						<i class="el-icon-plus" style="color: #03D3BC;"></i>
						关注
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		list: {
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	data() {
		return {};
	},
	methods: {
		async sub(index) {
			let item = this.list[index];
			if (item.is_focus === '0') {
				await this.$service.post('question/focusUser', { focus_user_id: item.user_id });
				item.is_focus = '1';
			} else {
				await this.$service.post('question/delFocusUser', { focus_user_id: item.user_id });
				item.is_focus = '0';
			}
		},
		navDetail(user_id) {
			this.$router.push({
				path: '/user/home',
				query: { user_id }
			});
		}
	}
};
</script>

<style scoped lang="less">
@import './fans.less';
</style>
